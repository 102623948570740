import React from 'react';

function NotFoundPage() {
  return (
    <main>
      <h2>404</h2>
      <p>Note is not found</p>
    </main>
  );
}

export default NotFoundPage;
